import { Controller } from "stimulus";

export default class extends Controller {
  static targets = [
    "categorySelect",
    "discountAmount",
    "percentage",
    "firstOrderDiscountPercentage",
    "secondOrderDiscountPercentage",
    "thirdOrderDiscountPercentage",
    "minValue",
    "firstOrderDiscountAmount",
    "secondOrderDiscountAmount",
  ]

  connect() {
    this.updateCategory()
  }

  updateCategory() {
    const value = this.categorySelectTarget.value

    const actions = {
      first_two_order_amount: () => {
        this.showTargets(["firstOrderDiscountAmount", "secondOrderDiscountAmount"]);
      },
      subscription_amount: () => {
        this.showTargets(["discountAmount"]);
      },
      first_order_amount: () => {
        this.showTargets(["discountAmount"]);
      },
      subscription: () => {
        this.showTargets(["percentage"]);
      },
      order_item: () => {
        this.showTargets(["percentage"]);
      },
      mixed: () => {
        this.showTargets(["firstOrderDiscountPercentage", "percentage"]);
      },
      first_three_orders: () => {
        this.showTargets(["firstOrderDiscountPercentage", "secondOrderDiscountPercentage", "thirdOrderDiscountPercentage"]);
      },
      min_value: () => {
        this.showTargets(["minValue", "percentage"]);
      },
      default: () => {
        this.hideAllTargets();
      },
    };

    this.hideAllTargets();

    (actions[value] || actions.default)();
  }

  showTargets(targets) {
    targets.forEach(target => {
      this[`${target}Target`].style.display = "block";
    });
  }

  hideTargets(targets) {
    targets.forEach(target => {
      const element = this[`${target}Target`];
      if (element) {
        element.style.display = "none";

        const input = element.querySelector("input");
        if (input) {
          input.value = "";
        }
      } else {
        console.warn(`${target}Target not found.`);
      }
    });
  }

  hideAllTargets() {
    const allTargets = [
      "firstOrderDiscountAmount",
      "secondOrderDiscountAmount",
      "discountAmount",
      "percentage",
      "firstOrderDiscountPercentage",
      "secondOrderDiscountPercentage",
      "thirdOrderDiscountPercentage",
      "minValue"
    ];
  
    this.hideTargets(allTargets);
  }
}